import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/Layout";
import { ContactPageTemplate } from "../../templates/contact-page";

const ContactPageThanks = ({ data }) => {
  return (
    <Layout>
      <ContactPageTemplate submitted />
    </Layout>
  );
};

ContactPageThanks.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPageThanks;
